// removes the spinner circle for axios loading bar
#nprogress .spinner-icon {
    display: none;
    // border-top-color: black !important;
    // border-left-color: black !important;
}

// #nprogress .bar {
//     // background: #f82e33 !important;
//     background: #dd090e !important;
// }

#nprogress .bar {
    // background: black !important;
}

#nprogress .peg {
    box-shadow: 0 0 10px red, 0 0 5px red !important;
}